import { createSlice } from '@reduxjs/toolkit'
export const appSlice = createSlice({
    name: 'app',
    initialState: {
        accountId: null
    },
    reducers: {
        setAccountId: (state, action) => {
            state.accountId = action.payload;
        }
    }
})

export const { setAccountId } = appSlice.actions

export default appSlice.reducer