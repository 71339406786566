export class OrderDetailsService {
  static getOrderDetails(requestpayload) {
    return fetch(process.env.REACT_APP_apiEndpoint + "/orderHistory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestpayload),
    }).then((response) => response.json());
  }

  static getLineLevelDetails(requestpayload) {
    return fetch(process.env.REACT_APP_apiEndpoint + "/orderHistoryLines", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestpayload),
    }).then((response) => response.json());
  }
}
