import { useEffect, useState } from "react";
import "./order-history.css";
import {
  ColumnDirective,
  ColumnsDirective,
  DetailRow,
  GridComponent,
  Page,
  parentsUntil,
} from "@syncfusion/ej2-react-grids";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { ExportService } from "../../services/export.service";
import { OrderDetailsService } from "../../services/order-details.service";
import { connect } from "react-redux";
import {
  CheckBoxSelection,
  MultiSelectComponent,
  Inject,
} from "@syncfusion/ej2-react-dropdowns";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import * as moment from "moment";
import LineLevelOrder from "../../components/line-level-order/line-level-order";
import { TooltipsService } from "../../services/tooltips.service";
function OrderHistory(props) {
  let [showLoader, setShowLoader] = useState(false);
  let orderData;
  let pageSizes = [15, 50, 100, 250, 500];
  let pageSettings = {
    pageSizes: pageSizes,
    pageSize: 15,
  };
  let selectionSettings = { type: "Single" };
  let filterSettings = { type: "Menu" };
  let [profileForm, setProfileForm] = useState({
    order: "",
    reference: "",
    po: "",
    invoice: "",
    shipdate: "",
    orderdate: "",
    type: [],
  });

  useEffect(() => {
    if (props.accountId) {
      getData();
    }
  // eslint-disable-next-line
  }, [props.accountId]);

  const handleFieldChange = (e) => {
    setProfileForm({
      ...profileForm,
      [e.target.name]: e.target.value,
    });
  };

  let orderTypes = [
    { Name: "Stock", Type: "Stock" },
    { Name: "Decorated", Type: "Decorated" },
    { Name: "Sublimation", Type: "Sublimation" },
    { Name: "Booking", Type: "Booking" },
    { Name: "Replacement", Type: "Replacement" },
  ];
  let checkFields = { text: "Name", value: "Type" };
  let popHeight = "350px";
  let mode = "CheckBox";
  function onOpen(args) {
    args.popup.element
      .querySelector(".e-footer .e-apply")
      .addEventListener("click", (e) => onSubmit(e));
  }

  let [ordersubarray, setordersubarray] = useState([]);
  let excelsubarray = [];

  const onSubmit = (event) => {
    if (event.keyCode === 13) {
      getData();
    }
  };

  function onClear() {
    let pf = { ...profileForm };
    pf.order = "";
    pf.reference = "";
    pf.po = "";
    pf.invoice = "";
    pf.shipdate = "";
    pf.orderdate = "";
    pf.type = [];
    pf.action = "clear";
    setProfileForm(pf);
    getData(pf);
  }

  useEffect(() => {
    if (profileForm.action === "clear") {
      let pf = { ...profileForm };
      pf.action = null;
      setProfileForm(pf);
      getData();
    }
  
  // eslint-disable-next-line
  }, [profileForm])
  
  let onSearch = () => getData();

  function getData(pf = null) {
    setShowLoader(true);
    const requestpayload = getRequestPayload(pf);

    OrderDetailsService.getOrderDetails(requestpayload)
      .then((data) => {
        if (data) {
          setShowLoader(false);
        }
        let arr = data.body;

        arr.forEach((element) => {
          const date = new Date(element.orderDate);
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

          element.orderDate = moment(date).format("MM/DD/yyyy");

          if (element.orderStatus === "Cancelled") {
            element.Total = 0;
          }

          if (element.Total !== null && element.Total !== "") {
            element.Total = Math.round(element.Total * 100) / 100;
            element.Total = element.Total.toFixed(2);
            element.Total = "$" + element.Total;
          }

          if (!element.carrierMultiple) {
            if (element.carrierInfo != null) {
              element.trackingNumber = element.carrierInfo[0].trackingNumber;
              element.invoice = element.carrierInfo[0].invoicePath;
              element.trackingvia = element.carrierInfo[0].carrierName;
              if (element.trackingvia.toLowerCase().includes("ups")) {
                element.trackinglink =
                  "https://www.ups.com/track?loc=null&tracknum=" +
                  element.carrierInfo[0].trackingNumber +
                  "&requester=WT/";
              } else if (element.trackingvia.toLowerCase().includes("fedex")) {
                element.trackinglink =
                  "https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=" +
                  element.carrierInfo[0].trackingNumber +
                  "&cntry_code=us";
              } else if (element.trackingvia.toLowerCase().includes("usps")) {
                element.trackinglink =
                  "https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLabels=" +
                  element.carrierInfo[0].trackingNumber;
              } else {
                element.trackinglink = "#";
              }
            } else {
              element.trackingNumber = "";
              element.invoice = "";
            }
          } else {
            element.trackingNumber = "Multiple";
            element.invoice = "Multiple";
          }
        });

        setordersubarray(arr);
      })
      .catch((err) => {
        console.log(err, err.statusText, "error");
      });
  }

  let grid;
  const rowdeSelect = (e) => {
    const tr = parentsUntil(e.target, "e-row");
    const trIdx = parseInt(tr?.getAttribute("data-rowindex") || "-1", 10);
    if (trIdx > -1 && grid && grid.detailRowModule) {
      grid.detailRowModule.collapse(trIdx);
    }
  };

  const rowSelect = (e) => {
    const tr = parentsUntil(e.target, "e-row");
    const trIdx = parseInt(tr.getAttribute("data-rowindex"), 10);
    grid.detailRowModule.expand(trIdx);
  };

  const toolbarClick = (args) => {
    if (args.element.id === "Grid_excelexport") {
      grid.excelExport();
    }
  };

  const statusClass = (props) => {
    let c = "";
    switch (props.orderStatus) {
      case "In Progress":
        c = "in-progress";
        break;
      case "Shipped":
        c = "shipped";
        break;
      case "Partially Shipped":
        c = "partial";
        break;
      case "Backordered":
        c = "back-order";
        break;
      case "Cancelled":
        c = "cancel";
        break;
      case "In Warehouse":
      case "In Decoration":
      case "In Production":
        c = "in-warehouse";
        break;
      default:
        c = "";
        break;
    }
    c += " default";
    return c;
  };

  const getRequestPayload = (pf = null) => {
    const requestpayload = {};
    let profile = pf || profileForm;
    let orderDate = { from: "", to: "" };
    if (profile.orderdate) {
      const orderFromDate = moment(new Date(profile.orderdate[0])).format(
        "MM/DD/yyyy"
      );
      const orderToDate = moment(new Date(profile.orderdate[1])).format(
        "MM/DD/yyyy"
      );
      orderDate = { from: orderFromDate, to: orderToDate };
    }


    requestpayload.customerNumber = props.accountId;
    requestpayload.orderNumber = profile.order.trim();
    requestpayload.refNumber = profile.reference.trim().toUpperCase();
    requestpayload.po = profile.po.trim().toUpperCase();
    requestpayload.orderDate = orderDate;
    requestpayload.type = profile.type;

    return requestpayload;
  }

  const excelToExport = () => {
    const requestpayload = getRequestPayload();

    OrderDetailsService.getOrderDetails(requestpayload)
      .then((data) => {
        orderData = [];
        excelsubarray = data.body;
        const custNo = excelsubarray[0].customerNumber;

        if (excelsubarray && excelsubarray.length > 0) {
          for (let i = 0, length = excelsubarray.length; i < length; i++) {
            if (excelsubarray.Total != null) {
              excelsubarray.Total = excelsubarray.Total.toFixed(2);
            }
            orderData.push(excelsubarray[i]);
          }
        }

        const edata = [];
        const udt = {
          data: [
            {
              A: "Order Date",
              B: "Order #",
              C: "Reference #",
              D: "PO",
              E: "Source",
              F: "Type",
              G: "Status",
              H: "Total($)",
            },
          ],
          skipHeader: true,
        };
        orderData.forEach((orders) => {
          udt.data.push({
            A: orders.orderDate,
            B: orders.orderNumber,
            C: orders.RefNumber,
            D: orders.PO,
            E: orders.Src,
            F: orders.Type,
            G: orders.orderStatus,
            H: orders.Total,
          });
        });
        edata.push(udt);

        ExportService.exportJsonToExcel(
          edata,
          "Customer_" + custNo + "_OrderHistoryData"
        );
      })
      .catch((err) => {
        console.log(err, err.statusText, "error");
      });
  };

  const rowDetailTemplate = (props) => {
    return (
      <LineLevelOrder
        lineLevelData={props}
        lineLevelorderNumber={props.orderNumber}
      />
    );
  };

  return (
    <div className="order-history-container">
      <div className="col-12" style={{ marginTop: "10px" }}>
        <div style={{ fontSize: "22px", fontFamily: "Slate Std Bold" }}>
          ORDER HISTORY
        </div>
      </div>
      <div className="col-12">
        <form className="filters">
          <div className="row" style={{ margin: 0 }}>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="e-input-group">
                <input
                  className="e-input"
                  type="text"
                  id="order_search"
                  name="order"
                  placeholder="Search by Order #"
                  onKeyUp={onSubmit}
                  onChange={handleFieldChange}
                  value={profileForm.order}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="e-input-group">
                <input
                  className="e-input"
                  type="text"
                  id="ref_search"
                  name="reference"
                  placeholder="Search by Reference #"
                  onKeyUp={onSubmit}
                  onChange={handleFieldChange}
                  value={profileForm.reference}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="e-input-group">
                <input
                  className="e-input"
                  type="text"
                  id="po_search"
                  name="po"
                  placeholder="Search by PO"
                  onKeyUp={onSubmit}
                  onChange={handleFieldChange}
                  value={profileForm.po}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <MultiSelectComponent
                id="multiselect-checkbox"
                dataSource={orderTypes}
                placeholder="Filter by Type"
                fields={checkFields}
                mode={mode}
                popupHeight={popHeight}
                showDropDownIcon={true}
                showSelectAll={true}
                name="type"
                onChange={handleFieldChange}
                value={profileForm.type}
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <DateRangePickerComponent
                name="orderdate"
                id="orderDateSearch"
                placeholder="Filter by Order Date"
                open={onOpen}
                onChange={handleFieldChange}
                value={profileForm.orderdate}
              />
            </div>
            <div
              className="col-lg-2 col-md-4 col-sm-12 row button-row"
              style={{ padding: "0px", margin: 0, flexWrap: "nowrap" }}
            >
              <div className="col">
                <ButtonComponent type="button" onClick={onSearch}>
                  Search
                </ButtonComponent>
              </div>
              <div className="col">
                <ButtonComponent type="button" onClick={onClear}>
                  Clear
                </ButtonComponent>
              </div>
            </div>
          </div>
        </form>
        <hr style={{ borderTop: "2px solid black", marginTop: "5px" }} />
      </div>
      <div
        className="order-search col-12"
        style={{ marginBottom: "5px" }}
      ></div>
      {showLoader && (
        <div className="d-flex justify-content-center loading-holder">
          <span class="spinner"></span>
        </div>
      )}
      <div className="col-12">
        <div style={{ marginTop: "5px" }}>
          <GridComponent
            ref={(g) => (grid = g)}
            key="ID"
            dataSource={ordersubarray}
            allowSorting={true}
            allowMultiSorting={true}
            allowPaging={true}
            filterSettings={filterSettings}
            selectionSettings={selectionSettings}
            pageSettings={pageSettings}
            width="100%"
            toolbarClick={toolbarClick}
            allowExcelExport={true}
            rowSelected={rowSelect}
            rowDeselected={rowdeSelect}
            detailTemplate={rowDetailTemplate}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText="Order Date"
                textAlign="Center"
                template={(props) => <div>{props.orderDate}</div>}
              />
              <ColumnDirective
                headerText="Order #"
                textAlign="Center"
                template={(props) => (
                  <TooltipComponent
                    content={
                      props.preflight !== true ? props.orderNumber : "Pending"
                    }
                    beforeOpen={TooltipsService.tooltipTester}
                  >
                    <b id="orderNumber">
                      {props.preflight !== true ? props.orderNumber : "Pending"}
                    </b>
                  </TooltipComponent>
                )}
              />
              <ColumnDirective
                headerText="Reference #"
                textAlign="Center"
                hideAtMedia="(min-width: 600px)"
                template={(props) => (
                  <TooltipComponent
                    content={props.RefNumber}
                    beforeOpen={TooltipsService.tooltipTester}
                  >
                    {props.RefNumber}
                  </TooltipComponent>
                )}
              />
              <ColumnDirective
                headerText="PO"
                textAlign="Center"
                hideAtMedia="(min-width: 600px)"
                template={(props) => (
                  <TooltipComponent
                    content={props.PO}
                    beforeOpen={TooltipsService.tooltipTester}
                  >
                    {props.PO}
                  </TooltipComponent>
                )}
              />
              <ColumnDirective
                headerText="Type"
                textAlign="Center"
                hideAtMedia="(min-width: 768px)"
                template={(props) => <div>{props.Type}</div>}
              />
              <ColumnDirective
                headerText="Status"
                headerTextAlign="Center"
                customAttributes={{ class: "status-cell" }}
                textAlign="Center"
                hideAtMedia="(min-width: 425px)"
                template={(props) => (
                  <div className="row" style={{ whiteSpace: "break-spaces" }}>
                    <div className={`col-8 ${statusClass(props)}`}>
                      {props.orderStatus}
                    </div>
                    {props.orderStatus === "In Pre-Production" &&
                      (props.sublimation_status === "Action Required" ||
                        props.sublimation_status === "Pending Approval" ||
                        props.sublimation_status === "Order Received" ||
                        props.sublimation_status === "Completed") && (
                        <div style={{ alignSelf: "center" }} className="col-2">
                          {(props.sublimation_status === "Action Required" ||
                            props.sublimation_status ===
                              "Pending Approval") && (
                            <div>
                              <img
                                style={{ width: "18px", height: "18px" }}
                                src="assets/logos/action_required.png"
                                alt="action required"
                                title="ON HOLD"
                              />
                            </div>
                          )}
                          {(props.sublimation_status === "Order Received" ||
                            props.sublimation_status === "Completed") && (
                            <div>
                              <img
                                style={{ width: "18px", height: "18px" }}
                                src="assets/logos/on_track.webp"
                                alt="on track"
                                title="IN PROCESS"
                              />
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                )}
              />
              <ColumnDirective
                headerText="Total"
                textAlign="Center"
                template={(props) => props.Total}
              />
            </ColumnsDirective>
            <Inject services={[DetailRow, Page]} />
          </GridComponent>
        </div>
        <div className="col-12" style={{ padding: "10px" }}>
          <div className="row">
            <div className="col-6">
              <ButtonComponent
                onClick={excelToExport}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="assets/logos/export xlsx.png"
                  alt="export excel"
                  style={{ marginRight: "5px" }}
                />
                EXCEL EXPORT
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    accountId: state.app.accountId,
  };
};
export default connect(mapStateToProps)(OrderHistory);
