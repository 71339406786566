import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import * as moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { OrderDetailsService } from "../../services/order-details.service";
import "./line-level-order.css";
import { TooltipsService } from "../../services/tooltips.service";

function LineLevelOrder(props) {
  let [lineLevelData, setLineLevelData] = useState(props.lineLevelData);
  let lineLevelOrderNumber = props.lineLevelorderNumber;
  let [lineLevelDetails, setLineLevelDetails] = useState(null);
  let [orderStatus, setOrderStatus] = useState(null);
  let [carrData, setCarrData] = useState([]);
  let [sublimationStatus, setSublimationStatus] = useState(null);
  let [sublimationStatusMessage, setSublimationStatusMessage] = useState(
    <div></div>
  );
  let grid;
  let customAttributes = { class: "quantitycss" };
  let [lineItemData, setLineItemData] = useState([]);
  let [linelevelTotal, setLinelevelTotal] = useState(null);

  let [orderTotal, setOrderTotal] = useState(null);

  const getOrderStatusClass = () => {
    let s = "";
    switch (orderStatus) {
      case "In Progress":
        s = "inprogress";
        break;
      case "Partially Shipped":
        s = "partial";
        break;
      case "Shipped":
        s = "shipped";
        break;
      case "Backordered":
        s = "back-order";
        break;
      case "Cancelled":
        s = "cancel";
        break;
      case "In Warehouse":
        s = "warehouse";
        break;
      default:
        s = "";
        break;
    }
    return s;
  };

  const addressBlock = (Title, address) => {
    return (
      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12">
        <div className="title">
          <b>{Title}</b>
        </div>
        {address != null && (
          <div>
            <div>{address.Name}</div>
            <div>{address.address1}</div>
            {address.address2 !== null && <div>{address.address2}</div>}
            {address.address3 !== null && <div>{address.address3}</div>}
            {address.address4 !== null && <div>{address.address4}</div>}
            <div>
              {address.city}, {address.State} {address.zipCode}
            </div>
          </div>
        )}
      </div>
    );
  };

  const imgNotFound = (props) => {
    props.target.src = "assets/logos/Img_not_found.png";
  };

  function onGridDataBound() {
    grid.autoFitColumns([
      "style",
      "item",
      "color",
      "qty",
      "size",
      "status",
      "shipdate",
      "price",
      "subTotal",
    ]);
  }

  const statusMap = {
    "Order Received": "Order-received.png",
    "Action Required": "Order-review.png",
    "Pending Approval": "Approval-needed.png",
    "Production Started": "In-production.png",
    "In Production": "In-production.png",
  };

  function initData() {
    let statusImage = "";
    if (lineLevelData.Type === "Sublimation") {
      if (lineLevelData.orderStatus === "Shipped") {
        statusImage = "Shipped.png";
      } else if (
        statusMap[lineLevelData.sublimation_status ?? lineLevelData.orderStatus]
      ) {
        statusImage =
          statusMap[
            lineLevelData.sublimation_status ?? lineLevelData.orderStatus
          ];
      }
    }
    if (statusImage) {
      setSublimationStatus("../assets/Status Info/" + statusImage);
    }
    let payload = {
      orderNumber: lineLevelOrderNumber,
      Type: lineLevelData.Type,
      customerNumber: lineLevelData.customerNumber,
      RefNumber: lineLevelData.RefNumber,
    };
    let lineLevelData2 = lineLevelData;
    OrderDetailsService.getLineLevelDetails(payload)
      .then((data) => {
        let lineOrderDetails = data.body;
        let lineLevelDetails = data.orderInfo;

        let lineItemList = [];
        lineOrderDetails.forEach((element) => {
          let lineOrderList = element.lineList;
          let index = 0;
          lineOrderList.forEach((inner) => {
            inner.lineNumber = element.lineNumber + ":" + index;
            lineItemList.push(inner);
            index++;
          });
        });
        let lineItemData = lineItemList;

        lineItemData.forEach((element) => {
          if (
            element.style.charAt(0) >= "A" &&
            element.style.charAt(0) <= "Z"
          ) {
            if (element.name.includes("-") && element.color.includes("/")) {
              let name = element.name.split("-")[0];
              name = name.trim();
              const color = element.color.replaceAll("/", "");
              element.imagelink =
                "https://www.foundersport.com/imageapi/" +
                name +
                "/" +
                color +
                "/f/";
            } else if (
              element.name.includes("-") &&
              element.color.search("/") === -1
            ) {
              let productName = element.name.split("-")[0];
              productName = productName.trim();
              element.imagelink =
                "https://www.foundersport.com/imageapi/" +
                productName +
                "/" +
                element.color +
                "/f/";
            } else if (
              element.name.search("-") === -1 &&
              element.color.includes("/")
            ) {
              const productColor = element.color.replaceAll("/", "");
              element.imagelink =
                "https://www.foundersport.com/imageapi/" +
                element.style +
                "/" +
                productColor +
                "/f/";
            } else {
              element.imagelink =
                "https://www.foundersport.com/imageapi/" +
                element.style +
                "/" +
                element.color +
                "/f/";
            }
          } else if (element.color.includes("/")) {
            const colorCode = element.color.replaceAll("/", "");

            element.imagelink =
              "https://www.foundersport.com/imageapi/" +
              element.style +
              "/" +
              colorCode +
              "/f/";
          } else {
            element.imagelink =
              "https://www.foundersport.com/imageapi/" +
              element.style +
              "/" +
              element.color +
              "/f/";
          }

          let shipDate = "";
          if (element.shipDate) {
            shipDate = element.shipDate.replace("Est.", "");
          }
          const date = new Date(shipDate.trim());
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
          if (element.shipDate != null) {
            if (element.shipDate.charAt(0) === "E") {
              element.shipDate = moment(date).format("MM/DD/yy");
              element.shipDate = "Est. " + element.shipDate;
            } else {
              element.shipDate = moment(date).format("MM/DD/yy");
            }
          }
          element.price = element.price.toFixed(2);
          element.subTotal = element.subTotal.toFixed(2);
          element.qty = parseInt(element.qty, 10);
        });

        let lineCancelTotal = 0;
        let lineItems = 0;
        for (let i = 0, length = lineItemData.length; i < length; i++) {
          if (lineItemData[i].status === "Cancelled") {
            lineCancelTotal += +lineItemData[i].subTotal;
          }
          let lineItemsTotal = +lineItems + +lineItemData[i].subTotal;
          lineItems = lineItemsTotal;
        }
        if (lineLevelData2.orderStatus === "Cancelled") {
          for (let i = 0, length = lineItemData.length; i < length; i++) {
            if (lineItemData[i].status === "Confirmed") {
              lineCancelTotal += +lineItemData[i].subTotal;
            }
          }
        }

        lineItems -= lineCancelTotal;

        let linelevelTotal = lineItems.toFixed(2);

        let shippingCharges = lineLevelDetails.Shipping;

        if (
          lineLevelDetails.Shipping === "null" ||
          lineLevelDetails.Shipping === "FREE"
        ) {
          shippingCharges = "0";
        }

        let ot =
          Number(linelevelTotal || 0) +
          Number(lineLevelDetails.Taxes || 0) +
          Number(lineLevelDetails.Discounts || 0) +
          Number(shippingCharges || 0);
        ot = Number(ot).toFixed(2);
        ot = "$" + ot;
        setOrderTotal(ot);

        // code for discounts

        if (
          lineLevelData2.Src === "b2b.allesonathletic.com" &&
          lineLevelData2.Type === "Sublimation" &&
          lineLevelData2.orderStatus !== "Shipped" &&
          lineLevelData2.orderStatus !== "Partially Shipped" &&
          lineLevelData2.orderStatus !== "Cancelled"
        ) {
          lineLevelDetails.Discounts = "Pending";
        } else if (
          lineLevelData2.orderStatus === "In Production" ||
          lineLevelData2.orderStatus === "In Pre-Production"
        ) {
          lineLevelDetails.Discounts = "Pending";
        } else if (
          lineLevelDetails.Discounts != null &&
          lineLevelDetails.Discounts >= 0
        ) {
          lineLevelDetails.Discounts = lineLevelDetails.Discounts.toFixed(2);
          lineLevelDetails.Discounts = "$" + lineLevelDetails.Discounts;
        } else if (lineLevelDetails.Discounts < 0) {
          lineLevelDetails.Discounts = Math.abs(lineLevelDetails.Discounts);
          lineLevelDetails.Discounts = lineLevelDetails.Discounts.toFixed(2);
          lineLevelDetails.Discounts = "$" + lineLevelDetails.Discounts;
          lineLevelDetails.Discounts = "-" + lineLevelDetails.Discounts;
        }

        // code for Taxes
        if (
          lineLevelData2.Src === "b2b.allesonathletic.com" &&
          lineLevelData2.Type === "Sublimation" &&
          lineLevelData2.orderStatus !== "Shipped" &&
          lineLevelData2.orderStatus !== "Partially Shipped" &&
          lineLevelData2.orderStatus !== "Cancelled"
        ) {
          lineLevelDetails.Taxes = "Pending";
        } else if (
          lineLevelData2.orderStatus === "In Production" ||
          lineLevelData2.orderStatus === "In Pre-Production"
        ) {
          lineLevelDetails.Taxes = "Pending";
        } else if (
          lineLevelDetails.Taxes != null &&
          lineLevelDetails.Taxes >= 0
        ) {
          lineLevelDetails.Taxes = lineLevelDetails.Taxes.toFixed(2);
          lineLevelDetails.Taxes = "$" + lineLevelDetails.Taxes;
        }
        // code for Shipping

        if (
          lineLevelData2.Src === "b2b.allesonathletic.com" &&
          lineLevelData2.Type === "Sublimation" &&
          lineLevelData2.orderStatus !== "Shipped" &&
          lineLevelData2.orderStatus !== "Partially Shipped" &&
          lineLevelData2.orderStatus !== "Cancelled"
        ) {
          lineLevelDetails.Shipping = "Pending";
        } else if (
          lineLevelData2.orderStatus === "In Production" ||
          lineLevelData2.orderStatus === "In Pre-Production"
        ) {
          lineLevelDetails.Shipping = "Pending";
        } else if (
          lineLevelDetails.Shipping != null &&
          lineLevelDetails.Shipping > 0
        ) {
          lineLevelDetails.Shipping = lineLevelDetails.Shipping.toFixed(2);
          lineLevelDetails.Shipping = "$" + lineLevelDetails.Shipping;
        } else if (lineLevelDetails.Shipping === 0) {
          lineLevelDetails.Shipping = "FREE";
        }
        if (
          lineLevelDetails.Shipping === "Pending" ||
          lineLevelDetails.Taxes === "Pending" ||
          lineLevelDetails.Discounts === "Pending"
        ) {
          setOrderTotal("Pending");
        }

        setOrderStatus(lineLevelData2.orderStatus);

        if (
          lineLevelDetails.carrierInfo &&
          lineLevelDetails.carrierInfo != null
        ) {
          let carrData = lineLevelDetails.carrierInfo;
          for (let i = 0, length = carrData.length; i < length; i++) {
            if (carrData[i].carrierName.toLowerCase().includes("ups")) {
              carrData[i].trackingLink =
                "https://www.ups.com/track?loc=null&tracknum=" +
                carrData[i].trackingNumber +
                "&requester=WT/";
            } else if (
              carrData[i].carrierName.toLowerCase().includes("fedex")
            ) {
              carrData[i].trackingLink =
                "https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=" +
                carrData[i].trackingNumber +
                "&cntry_code=us";
            } else if (carrData[i].carrierName.toLowerCase().includes("usps")) {
              carrData[i].trackingLink =
                "https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLabels=" +
                carrData[i].trackingNumber;
            } else {
              carrData[i].trackingLink = "#";
            }
          }
          for (let i = 0, length = carrData.length; i < length; i++) {
            if (carrData[i].invoicePath != null) {
              const invNo = carrData[i].invoicePath.substring(
                carrData[i].invoicePath.lastIndexOf("/") + 1,
                carrData[i].invoicePath.length
              );
              carrData[i].invoiceNumber = invNo.substring(
                0,
                invNo.indexOf("_")
              );
            }
          }
          setCarrData(carrData);
        }

        if (
          lineLevelDetails.shippingAddress &&
          lineLevelDetails.shippingAddress.length > 0
        ) {
          lineLevelDetails.shippingAddress =
            lineLevelDetails.shippingAddress[0];
        }
        if (
          lineLevelDetails.billingAddress &&
          lineLevelDetails.billingAddress.length > 0
        ) {
          lineLevelDetails.billingAddress = lineLevelDetails.billingAddress[0];
        }

        setLineLevelDetails(lineLevelDetails);
        setLinelevelTotal(linelevelTotal);
        setLineLevelData(lineLevelData2);
        setLineItemData(lineItemData);
      })
      .catch((err) => {
        console.log(err, err.statusText, "error");
      });
    const statusMessages = {
      "Action Required": {
        Icon: "assets/logos/exclamation.png",
        BorderColor: "#fed105",
        TopMessage: `
          <b>MISSING INFORMATION: </b>We need some additional information from you.
          <b>Your order is ON HOLD.</b>`,
        BottomMessage: `We've emailed you at <b>${lineLevelData2.email}</b> with more information regarding your order and next steps.`,
      },
      Completed: {
        Icon: "assets/logos/on_track_detail.webp",
        BorderColor: "#44bf7e",
        TopMessage: `<b>YOUR ORDER IS APPROVED AND IS CURRENTLY ON TRACK FOR ON-TIME PRODUCTION.</b>`,
        BottomMessage: `Since approved for Production, we can no longer accept any changes or cancel your order.`,
      },
      "Order Received": {
        Icon: "assets/logos/on_track_detail.webp",
        BorderColor: "#44bf7e",
        TopMessage: `
        <b>WE HAVE RECEIVED YOUR ORDER.</b>
        We are reviewing it now and will update you shortly.`,
        BottomMessage: `If we have any questions, we will email you at <b>${lineLevelData2.email}</b>.`,
      },
      "Pending Approval": {
        Icon: "assets/logos/exclamation.png",
        BorderColor: "#fed105",
        TopMessage: `
        <b>APPROVAL NEEDED: </b>We are awaiting approval from you before we can proceed.
        <b>Your order is ON HOLD.</b>`,
        BottomMessage: `Please check the instructions in the email sent to <b>${lineLevelData2.email}</b> to approve your order on the OMSdealer page.`,
      },
    };
    if (lineLevelData2.orderStatus === "In Pre-Production") {
      if (
        typeof statusMessages[lineLevelData2.sublimation_status] !== "undefined"
      ) {
        sublimationStatusMessage =
          statusMessages[lineLevelData2.sublimation_status];
      }
    }
    setSublimationStatusMessage(sublimationStatusMessage);
  }
  useEffect(() => {
    initData();
  // eslint-disable-next-line
  }, []);

  return (
    <div className="line-level-content" style={{ padding: "15px" }}>
      <div className="row info-row">
        <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12">
          <div>
            <TooltipComponent
              content={
                "Order #: " + lineLevelData.preflight !== true
                  ? lineLevelOrderNumber
                  : "Pending"
              }
              beforeOpen={TooltipsService.tooltipTester}
            >
              <b>Order #: </b>
              <b style={{ color: "rgb(48, 166, 235)" }}>
                {lineLevelData.preflight !== true
                  ? lineLevelOrderNumber
                  : "Pending"}
              </b>
            </TooltipComponent>
            <TooltipComponent
              className="max-600"
              beforeOpen={TooltipsService.tooltipTester}
              content={"Ref #:" + lineLevelData.RefNumber}
            >
              <b>Ref #: </b>
              <span>{lineLevelData.RefNumber}</span>
            </TooltipComponent>
            <TooltipComponent
              className="max-600"
              beforeOpen={TooltipsService.tooltipTester}
              content={"PO: " + lineLevelData.PO}
            >
              <b>PO : </b>
              <span>{lineLevelData.PO}</span>
            </TooltipComponent>
            <TooltipComponent
              beforeOpen={TooltipsService.tooltipTester}
              content={"Source: " + lineLevelData.Src}
            >
              <b>Source: </b>
              <span>{lineLevelData.Src}</span>
            </TooltipComponent>
            <TooltipComponent
              className="max-768"
              beforeOpen={TooltipsService.tooltipTester}
              content={"Type: " + lineLevelData.Type}
            >
              <b>Type: </b>
              <span>{lineLevelData.Type}</span>
            </TooltipComponent>
          </div>
          <div
            className={`default ${getOrderStatusClass()}`}
            style={{ marginTop: "5px" }}
          >
            {orderStatus}
          </div>
        </div>
        {lineLevelData.orderStatus !== "In Pre-Production" &&
          addressBlock("Billing Address", lineLevelDetails?.billingAddress)}
        {lineLevelData.orderStatus !== "In Pre-Production" &&
          addressBlock("Shipping Address", lineLevelDetails?.shippingAddress)}
        {lineLevelData.orderStatus !== "In Pre-Production" &&
          carrData.length > 0 && (
            <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12">
              <div className="title">
                <b>Invoices</b>
              </div>
              {carrData.map((cData) => {
                return (
                  <div key={cData.invoiceNumber}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={cData.invoicePath}
                      style={{ textDecoration: "underline" }}
                      id="invoiceDownload"
                    >
                      {cData.invoiceNumber}
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        {lineLevelData.orderStatus !== "In Pre-Production" &&
          carrData.length > 0 && (
            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
              <div className="title">
                <b>Shipment Tracking</b>
              </div>
              {carrData.map((cData) => {
                return (
                  cData.trackingLink !== null &&
                  cData.trackingLink.length > 0 && (
                    <div key={cData.trackingLink}>
                      {moment(cData.shipDate).format("MM/DD/yyyy")}:&nbsp;
                      {cData.trackingLink[0] !== "#" && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={cData.trackingLink}
                          style={{ textDecoration: "underline" }}
                          id="trackingNumber"
                        >
                          {cData.trackingNumber} ({cData.carrierName})
                        </a>
                      )}
                      {cData.trackingLink[0] === "#" && (
                        <span>
                          {cData.trackingNumber} ({cData.carrierName})
                        </span>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          )}
      </div>
      <div>
        {(sublimationStatus !== null || sublimationStatusMessage !== null) &&
          (["In Pre-Production", "Action Required", "PendingApproval"].indexOf(
            lineLevelData.sublimation_status
          ) > -1 ||
            ["In Pre-Production", "Action Required", "PendingApproval"].indexOf(
              lineLevelData.orderStatus
            ) > -1) && (
            <div
              className="row"
              style={{
                marginBottom: "25px",
                marginTop: "15px",
                border: "1px solid rgb(219, 216, 216)",
              }}
            >
              <div className="col-lg-6 col-md-12" style={{ padding: 0 }}>
                <div className="order-status">
                  <img
                    src={sublimationStatus}
                    style={{ maxWidth: "100%", maxHeight: "130px" }}
                    alt="sublimation status"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                {sublimationStatusMessage !== null && (
                  <div
                    className="status-box"
                    style={{
                      borderColor: sublimationStatusMessage.BorderColor,
                    }}
                  >
                    <div className="row">
                      <div className="left">
                        <img
                          src={sublimationStatusMessage.Icon}
                          alt="sublimation status icon"
                        />
                      </div>
                      <div className="right">
                        <div
                          className="top-message"
                          dangerouslySetInnerHTML={{
                            __html:
                              sublimationStatusMessage.TopMessage ||
                              "<div></div>",
                          }}
                        ></div>
                        <div
                          className="bottom-message"
                          dangerouslySetInnerHTML={{
                            __html:
                              sublimationStatusMessage.BottomMessage ||
                              "<div></div>",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
      {lineLevelData.orderStatus !== "In Pre-Production" && (
        <div style={{ margin: "0 -15px" }} className="sub-grid-holder">
          <GridComponent
            ref={(g) => (grid = g)}
            key="lineNumber"
            dataSource={lineItemData}
            allowPdfExport={true}
            allowExcelExport={true}
            dataBound={onGridDataBound}
            allowResizing={false}
            allowSorting={true}
            allowMultiSorting={true}
            width="100%"
          >
            <Inject services={[Resize]} />
            <ColumnsDirective>
              <ColumnDirective
                width="40"
                maxWidth="40"
                minWidth="40"
                template={(props) =>
                  lineLevelData.Type === "Stock" && (
                    <img
                      src={props.imagelink}
                      onError={imgNotFound}
                      alt="product"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )
                }
              />
              <ColumnDirective
                field="style"
                headerText="Style #"
                minWidth="60"
                template={(props) => (
                  <TooltipComponent
                    beforeOpen={TooltipsService.tooltipTester}
                    content={props.style}
                  >
                    {props.style}
                  </TooltipComponent>
                )}
              />
              <ColumnDirective
                field="item"
                headerText="Item #"
                template={(props) => (
                  <TooltipComponent
                    beforeOpen={TooltipsService.tooltipTester}
                    content={props.item}
                  >
                    {props.item}
                  </TooltipComponent>
                )}
              />
              <ColumnDirective
                field="name"
                headerText="Name"
                hideAtMedia="(min-width:860px)"
                template={(props) => (
                  <TooltipComponent
                    beforeOpen={TooltipsService.tooltipTester}
                    content={props.name.replace('"', "''")}
                  >
                    {props.name}
                  </TooltipComponent>
                )}
              />
              <ColumnDirective
                field="color"
                headerText="Color"
                minWidth="30"
                template={(props) => (
                  <TooltipComponent
                    beforeOpen={TooltipsService.tooltipTester}
                    content={props.color}
                  >
                    {props.color}
                  </TooltipComponent>
                )}
              />
              <ColumnDirective field="size" headerText="Size" minWidth="30" />
              <ColumnDirective
                field="qty"
                headerText="Qty"
                textAlign="Left"
                customAttributes={customAttributes}
                template={(props) => <div>{props.qty}</div>}
              />
              <ColumnDirective
                field="status"
                headerText="Status"
                minWidth="30"
                template={(props) => (
                  <TooltipComponent
                    beforeOpen={TooltipsService.tooltipTester}
                    content={props.status}
                    className={[
                      props.status === "Backordered" ? "line_back_order" : "",
                      props.status === "Shipped" ? "lineshipped" : "",
                      props.status === "Cancelled" ? "linecancel" : "",
                    ].join(" ")}
                  >
                    {props.status}
                  </TooltipComponent>
                )}
              />
              <ColumnDirective
                field="shipdate"
                headerText="Ship Date"
                hideAtMedia="(min-width: 700px)"
                minWidth="10"
                template={(props) => (
                  <TooltipComponent
                    beforeOpen={TooltipsService.tooltipTester}
                    content={props.shipDate}
                    className={[
                      props.status === "Backordered" ? "line_back_order" : "",
                      props.status === "Shipped" ||
                      props.status === "In Production"
                        ? "lineshipped"
                        : "",
                    ].join(" ")}
                  >
                    {props.shipDate}
                  </TooltipComponent>
                )}
              />
              <ColumnDirective
                field="price"
                headerText="Price/Piece"
                minWidth="10"
                hideAtMedia="(min-width: 860px)"
                textAlign="Right"
                headerTextAlign="Right"
                width="200"
                template={(props) => <>${props.price}</>}
              />
              <ColumnDirective
                field="subTotal"
                headerText="Subtotal"
                minWidth="10"
                hideAtMedia="(min-width: 700px)"
                textAlign="Right"
                headerTextAlign="Right"
                width="200"
                template={(props) => <>${props.subTotal}</>}
              />
            </ColumnsDirective>
          </GridComponent>
        </div>
      )}
      {lineLevelData.orderStatus !== "In Pre-Production" && (
        <div className="sub-tile-holder">
          {lineItemData.map((lineItem) => (
            <div className="sub-tile" key={lineItem.lineNumber}>
              <div className="row">
                <div className="card">
                  <div className="card-header">
                    {lineItem.name}
                    {lineItem.lineNumber}
                  </div>
                  <div className="card-body">
                    <div>
                      {lineLevelData.Type === "Stock" && (
                        <div className="image-col">
                          <div>
                            <img
                              src={lineItem.imagelink}
                              onError={imgNotFound}
                              style={{ width: "50px", height: "50px" }}
                              alt="product"
                            />
                          </div>
                        </div>
                      )}
                      <div className="data-col">
                        <div className="row">
                          <div className="col-6">
                            <b>Style #:</b> {lineItem.style}
                          </div>
                          <div className="col-6">
                            <b>Item #:</b>
                            {lineItem.item}
                          </div>
                          <div className="col-6">
                            <b>Color:</b> {lineItem.color}
                          </div>
                          <div className="col-6">
                            <b>Size:</b> {lineItem.size}
                          </div>
                          <div className="col-6">
                            <b>Status:</b> {lineItem.status}
                          </div>
                          <div className="col-6">
                            <b>Ship Date:</b> {lineItem.shipDate}
                          </div>
                          <div className="col-6">
                            <b>Quantity:</b> {lineItem.qty}
                          </div>
                          <div className="col-6">
                            <b>Price Per Unit:</b> ${lineItem.price}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    Subtotal: ${lineItem.subTotal}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {lineLevelData.orderStatus !== "In Pre-Production" && (
        <div className="col-12" style={{ marginTop: "10px" }}>
          <div className="row">
            <div
              className="col-12"
              style={{ textAlign: "right", fontFamily: "Slate Std Regular" }}
            >
              <div className="row">
                <div className="col-md-6 col-12">
                  {((lineLevelData.sublimation_status ||
                    lineLevelData.orderStatus) !== "Action Required" ||
                    (lineLevelData.sublimation_status ||
                      lineLevelData.orderStatus) !== "Pending Approval") && (
                    <div
                      className="order-status"
                      style={{ textAlign: "center" }}
                    >
                      {sublimationStatus && <img
                        src={sublimationStatus}
                        style={{
                          width: "auto",
                          maxHeight: "130px",
                          maxWidth: "100%",
                        }}
                        alt="order status"
                      />}
                    </div>
                  )}
                </div>
                <div
                  className="col-md-6 col-12 row"
                  style={{ paddingRight: "0px" }}
                >
                  <div className="col-10">Product Subtotal</div>
                  <div className="col-2" style={{ paddingRight: "0px" }}>
                    ${linelevelTotal}
                  </div>
                  <div className="col-10">Charges/Discounts</div>
                  <div className="col-2" style={{ paddingRight: "0px" }}>
                    {lineLevelDetails?.Discounts}
                  </div>
                  <div className="col-10">Taxes</div>
                  <div className="col-2" style={{ paddingRight: "0px" }}>
                    {lineLevelDetails?.Taxes}
                  </div>
                  <div className="col-10">Shipping</div>
                  <div className="col-2" style={{ paddingRight: "0px" }}>
                    {lineLevelDetails?.Shipping}
                  </div>
                  <div
                    className="col-10"
                    style={{ fontFamily: "Slate Std Bold" }}
                  >
                    TOTAL
                  </div>
                  <div className="col-2" style={{ paddingRight: "0px" }}>
                    {orderTotal}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    accountId: state.app.accountId,
  };
};
export default connect(mapStateToProps)(LineLevelOrder);
