import './App.css';
import { setAccountId } from './actions/order-history.actions';
import { useEffect} from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useDispatch} from 'react-redux';
import OrderHistory from './pages/order-history/order-history';

function App() {
  var IframeMessengerSdk = window.IframeMessengerSdk;
  var isEmbeddedInIframe = false;
  var messenger;
  const dispatch = useDispatch();

  var initIframeMessenger = () => {
    isEmbeddedInIframe = !(window.location === window.parent.location);
    console.log(`iframe embed mode:  ${isEmbeddedInIframe}`);

    if (isEmbeddedInIframe) {
      if (typeof IframeMessengerSdk !== 'undefined') {
        const publisher = (window && window.parent) || window;
        const subscriber = window;
        if (publisher && subscriber) {
          messenger = new IframeMessengerSdk({
            publisher,
            subscriber,
          });
          messenger.on('initData', (initData) => {
            console.log(
              'if-messenger.on.initData: ' + JSON.stringify(initData)
            );
            dispatch(setAccountId(initData.accountId))
          });
        }
      } else {
        console.warn('iframe-messenger-sdk not loaded');
      }
    }
  }

  useEffect(() => {
    initIframeMessenger();
    if (!isEmbeddedInIframe || !messenger) {
      dispatch(setAccountId('027946'))
    }
  // eslint-disable-next-line
  }, [isEmbeddedInIframe, messenger, initIframeMessenger]);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<OrderHistory/>}></Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
